// Autogenerated using mud system-types
import BuildSystem from "../abi/BuildSystem.json";
import BulkSetStateSystem from "../abi/BulkSetStateSystem.json";
import BulkStakeSystem from "../abi/BulkStakeSystem.json";
import BulkTransferSystem from "../abi/BulkTransferSystem.json";
import ClaimSystem from "../abi/ClaimSystem.json";
import ComponentDevSystem from "../abi/ComponentDevSystem.json";
import CraftSystem from "../abi/CraftSystem.json";
import CreativeBuildSystem from "../abi/CreativeBuildSystem.json";
import Init2System from "../abi/Init2System.json";
import InitSystem from "../abi/InitSystem.json";
import MineSystem from "../abi/MineSystem.json";
import NameSystem from "../abi/NameSystem.json";
import OccurrenceSystem from "../abi/OccurrenceSystem.json";
import StakeSystem from "../abi/StakeSystem.json";
import TransferSystem from "../abi/TransferSystem.json";

export const SystemAbis = {
  "system.Build": BuildSystem.abi,
  "ember.system.bulkSetState": BulkSetStateSystem.abi,
  "system.BulkStake": BulkStakeSystem.abi,
  "system.BulkTransfer": BulkTransferSystem.abi,
  "system.Claim": ClaimSystem.abi,
  "ember.system.componentDev": ComponentDevSystem.abi,
  "system.Craft": CraftSystem.abi,
  "system.CreativeBuild": CreativeBuildSystem.abi,
  "system.Init2": Init2System.abi,
  "system.Init": InitSystem.abi,
  "system.Mine": MineSystem.abi,
  "system.Name": NameSystem.abi,
  "system.Occurrence": OccurrenceSystem.abi,
  "system.Stake": StakeSystem.abi,
  "system.Transfer": TransferSystem.abi,
};
